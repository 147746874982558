import './styles.scss';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { CustomPagination } from '@components/modules/pagination';
import { PERMISSIONS } from '@constants/permission';
import { IContact } from '@interfaces/contact';
import { useModal } from '@refinedev/antd';
import { BaseKey, BaseRecord, useDelete, useList, usePermissions } from '@refinedev/core';
import { toLocaleDateTime } from '@utils/date';
import { getPublicMediaUrl } from '@utils/resource';
import { Button, Dropdown, Menu, Modal, Popconfirm, Table, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContactNoteItemForm } from './note-form';

export const PAGE_SIZE = 3;
export const ContactNoteList: React.FC<{ contactId: string }> = ({ contactId }) => {
    const [data, setData] = useState<any>();
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
    const { data: permissions } = usePermissions<string[]>();

    const {
        data: noteData,
        isFetching: noteLoading,
        refetch: noteRefetch,
    } = useList<IContact.IContact>({
        resource: `v1/contact-notes`,
        config: {
            hasPagination: false,
            filters: [
                {
                    field: 'contactId',
                    operator: 'eq',
                    value: contactId,
                },
                {
                    field: 'pageIndex',
                    operator: 'eq',
                    value: pageIndex,
                },
                {
                    field: 'pageSize',
                    operator: 'eq',
                    value: pageSize,
                },
            ],
        },
    });
    const { t } = useTranslation(['common', 'contact']);
    const { mutate, isLoading: loading } = useDelete<BaseRecord>();

    const deleteNote = async (id: string) => {
        if (!id) {
            return;
        }
        mutate(
            {
                resource: 'v1/contact-notes',
                id: id as BaseKey,
                successNotification: {
                    message: t('delete_success', { ns: 'common' }),
                    type: 'success',
                },
                errorNotification: { message: t('delete_error', { ns: 'common' }), type: 'error' },
            },
            {
                onSuccess: (_) => {
                    const _isLastItem = noteData?.data.length === 1;
                    _isLastItem && pageIndex > 1 ? setPageIndex((prev) => prev - 1) : noteRefetch();
                },
            },
        );
    };

    const convertData = (data: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, 'text/html');
        const text = doc.body.textContent || '';
        return text;
    };

    const menu = (id: string, record: any) => (
        <Menu mode="vertical">
            {(permissions || []).includes(PERMISSIONS.WL_CONTACT_EDIT) && (
                <Menu.Item
                    key="1"
                    icon={
                        <Button
                            onClick={() => handleEditNote(record)}
                            className="contact-button"
                            type="text"
                            icon={<EditOutlined />}
                        >
                            {t('edit', { ns: 'common' })}
                        </Button>
                    }
                ></Menu.Item>
            )}
            <Menu.Item
                key="2"
                icon={
                    <Popconfirm
                        placement="topLeft"
                        title={t('buttons.confirm', { ns: 'common' })}
                        onConfirm={() => deleteNote(id)}
                        okText={t('actions.delete', { ns: 'common' })}
                        cancelText={t('actions.no', { ns: 'common' })}
                        className="w-full"
                        icon={<></>}
                    >
                        <Button
                            className="p-0 border-0 delete-button"
                            danger
                            icon={<DeleteOutlined />}
                        >
                            {t('actions.delete', { ns: 'common' })}
                        </Button>
                    </Popconfirm>
                }
            ></Menu.Item>
        </Menu>
    );
    const {
        modalProps: modalNoteEditProps,
        show: showEditModalNote,
        close: closeEditModalNote,
    } = useModal();

    const handleEditNote = (record: any) => {
        setData(record);
        showEditModalNote();
    };

    useEffect(() => {
        noteRefetch();
    }, [contactId, pageIndex]);

    return (
        <>
            <div className="note-list-wrapper overflow-hidden ">
                <div className="list-content table-wrapper">
                    <Table
                        dataSource={noteData?.data}
                        loading={noteLoading}
                        rowKey="id"
                        pagination={false}
                        tableLayout="fixed"
                        scroll={{ x: '600px' }}
                    >
                        <Table.Column
                            title={<>{t('contacts.note_content.title', { ns: 'contact' })}</>}
                            dataIndex="content"
                            key="content"
                            width={100}
                            render={(row, _) => (
                                <div className="flex justify-between items-center ">
                                    <div className="flex justify-start items-center">
                                        <p className="table-tbody-text">
                                            {row ? convertData(row) : null}
                                        </p>
                                    </div>
                                </div>
                            )}
                        />
                        <Table.Column
                            title={<>{t('contacts.note_content.date', { ns: 'contact' })}</>}
                            dataIndex="createdAt"
                            key="createdAt"
                            width={100}
                            render={(row, _) => (
                                <div className="flex justify-between items-center ">
                                    <div className="flex justify-start items-center">
                                        <p className="table-tbody-text">
                                            {row ? toLocaleDateTime(new Date(row)) : null}
                                        </p>
                                    </div>
                                </div>
                            )}
                        />
                        <Table.Column
                            title={<>{t('contacts.note_content.attachment', { ns: 'contact' })}</>}
                            dataIndex="attachments"
                            key="attachments"
                            width={100}
                            render={(_, records: any) => {
                                return (
                                    <div className="flex justify-between items-center ">
                                        <div className="flex justify-start items-center">
                                            <p className="table-tbody-text">
                                                <Upload
                                                    className="upload-list-inline"
                                                    listType="text"
                                                    multiple
                                                    showUploadList={{
                                                        showDownloadIcon: false,
                                                        downloadIcon: 'Download',
                                                        showRemoveIcon: false,
                                                    }}
                                                    fileList={records.attachments.map(
                                                        (item: any) => {
                                                            return {
                                                                ...item,
                                                                name:
                                                                    item.name.length > 15
                                                                        ? item.name.substring(
                                                                              0,
                                                                              15,
                                                                          ) + '...'
                                                                        : item.name,
                                                                uid: item.id as string,
                                                                url: getPublicMediaUrl(item.url),
                                                            };
                                                        },
                                                    )}
                                                ></Upload>
                                            </p>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                        <Table.Column
                            dataIndex="id"
                            key="action"
                            width={50}
                            fixed="right"
                            render={(id, record: any, _) => (
                                <div
                                    className="flex justify-between items-center"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <Dropdown
                                        overlay={menu(id, record)}
                                        placement="bottomRight"
                                        className="w-8 ml-auto"
                                        arrow
                                        overlayClassName="invoice-dropdown-container"
                                        trigger={['click']}
                                    >
                                        <div>
                                            <img
                                                src="/images/icons/dots-vertical.svg"
                                                alt="more"
                                                className="cursor-pointer"
                                            />
                                        </div>
                                    </Dropdown>
                                </div>
                            )}
                        />
                    </Table>
                </div>
                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        pageSize={PAGE_SIZE}
                        total={noteData?.total}
                        current={pageIndex}
                        onChange={(currentPage: number, size: number) => {
                            setPageIndex(currentPage);
                            setPageSize(size);
                        }}
                        showSizeChanger={false}
                    />
                </div>

                <Modal
                    {...modalNoteEditProps}
                    title="Edit note"
                    maskClosable={false}
                    footer={null}
                    width={650}
                    wrapClassName="payment-modal"
                >
                    <ContactNoteItemForm
                        contactId={contactId}
                        data={data}
                        onCancelEdit={() => {
                            closeEditModalNote();
                        }}
                        onUpdate={(data) => {
                            setData({
                                ...data,
                                content: data.content,
                                attachments: data.attachments,
                            });
                            closeEditModalNote();
                        }}
                    />
                </Modal>
            </div>
        </>
    );
};
