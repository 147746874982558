import './styles.scss';

import { OTP_RESOURCE_NAME } from '@constants/index.constant';
import { Otp } from '@interfaces/otp';
import { IUser } from '@interfaces/user';
import {
    BaseKey,
    useApiUrl,
    useCreate,
    useCustomMutation,
    useGetIdentity,
    useIsAuthenticated,
    useNavigation,
    useOne,
} from '@refinedev/core';
import Response from '@responses/response';
import { Button, Col, Popconfirm, Row, Spin, notification } from 'antd';
import { TOKEN_KEY } from 'authProvider';
import { OtpConfirm } from 'components/modules/otp-confirm';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { maskPhone } from 'utils/string';

import { useParams } from 'react-router-dom';
import { QuoteStatus } from '../../enums/quote-status.enum';
import { IQuote } from '../../interfaces/quote';
import { FONT_INTER } from '../../utils/fonts';
import { formatDate, formatPrice } from '../../utils/resource';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IService } from '@interfaces/service';
import { useSelect } from '@refinedev/antd';
import { keyBy } from 'lodash';

type QuoteAction = 'approve' | 'reject' | null;

export const QuoteDetailsIndex: React.FC = () => {
    const { t } = useTranslation(['quote', 'common']);
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [rejectLoading, setRejectLoading] = useState<boolean>(false);
    const { goBack, push } = useNavigation();
    const { isSuccess: isAuthenticated } = useIsAuthenticated();
    const componentToPrint = useRef<any>();
    const { mutate: sendOtp, isLoading: isSendingOtp } = useCreate<Response<string>>();

    const { data: useIdentity } = useGetIdentity<IUser.IUserDto>();
    const [isOpenOtp, setIsOpenOtp] = useState<boolean>(false);
    const [action, setAction] = useState<QuoteAction>(null);
    const { data: userIdentity } = useGetIdentity<IUser.IUserDto>();

    const {
        refetch: quoteRefetch,
        data: quoteData,
        isLoading,
        error,
    } = useOne<IQuote.QuoteDetails>({
        resource: 'v1/quotes',
        id: id as BaseKey,
        queryOptions: {
            enabled: false,
        },
        errorNotification: {
            type: 'error',
            message: t('quotes.error.quote_has_expired'),
        },
    });

    const getPlanItemInformation = (planItem: IQuote.IPaymentPlanItem): IQuote.IPaymentPlanItem => {
        const quote = quoteData?.data;
        const result = { ...planItem };
        const quoteItem = (quote?.items || []).find(
            (quoteItem) => quoteItem.id === planItem.quoteItemId,
        );
        if (quoteItem) {
            result.frequency = quoteItem?.frequency;
            result.quoteItemName = quoteItem?.productName ?? '';
        }
        return result;
    };

    let token: string | null = null;
    const tokenKey = localStorage.getItem(TOKEN_KEY);
    const urlParams = new URLSearchParams(window.location.search);
    token = urlParams.get('token');

    useEffect(() => {
        if (error) {
            const { statusCode } = error as { statusCode: number };
            if (statusCode === 404) {
                push('/404');
            }
        }
    }, [error]);

    if (!isAuthenticated) {
        if (!token && !tokenKey) {
            push('/404');
        } else {
            quoteRefetch();
        }
    } else {
        quoteRefetch();
    }

    const { mutate } = useCustomMutation<any>();
    const apiUrl = useApiUrl();

    const quote: IQuote.QuoteDetails | undefined = quoteData?.data;

    const getTotalPrice = () => {
        if (quoteData?.data?.items) {
            let total = 0;
            // eslint-disable-next-line no-unsafe-optional-chaining
            for (const item of quoteData?.data?.items) {
                total += item.total;
            }

            return total;
        }
        return 0;
    };

    const getPlanTotalPrice = (plan: IQuote.IPlan): number => {
        return (plan?.paymentPlanItems || []).reduce((prev, next) => prev + (next?.total ?? 0), 0);
    };

    const handleQuoteAction = (type: QuoteAction) => {
        if (isAuthenticated) {
            switch (type) {
                case 'approve':
                    approve();
                    break;
                case 'reject':
                    rejectQuote();
                    break;
                default:
                    break;
            }
        } else {
            setAction(type);
            handleSendOtp();
        }
    };

    const handleSendOtp = () => {
        if (!quote?.id) {
            notification.error({
                message: t('otp.errors.id_not_found', { ns: 'common' }),
                type: 'error',
            });
            return;
        }

        const payload: Otp.ISendOtp = {
            id: quote.id,
            resourceName: OTP_RESOURCE_NAME.QUOTE,
            email: quote.contactEmail,
        };
        sendOtp(
            {
                resource: 'v1/otp/code',
                values: payload,
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: () => {
                    setIsOpenOtp(true);
                },
                onError: (error, _, __) => {
                    if (error.statusCode === 401) {
                        notification.error({
                            message: t('otp.errors.item_is_unauthorized', { ns: 'common' }),
                            type: 'error',
                        });
                        return;
                    }

                    if (error.message === 'can_not_create_otp_code') {
                        notification.error({
                            message: t('otp.errors.can_not_create_otp_code', { ns: 'common' }),
                            type: 'error',
                        });
                        return;
                    }
                    notification.error({
                        message: t('otp.errors.send_otp_failed', { ns: 'common' }),
                        type: 'error',
                    });
                },
            },
        );
    };

    const rejectQuote = () => {
        setRejectLoading(true);
        mutate(
            {
                url: `${apiUrl}/v1/quotes/${id}/reject`,
                method: 'post',
                values: {},
            },
            {
                onError: (error) => {
                    // An error happened!
                    console.error(error);
                    setLoading(false);
                },
                onSuccess: () => {
                    goBack();
                },
            },
        );
        //reset quote action
        setAction(null);
    };

    const approve = () => {
        setLoading(true);
        mutate(
            {
                url: `${apiUrl}/v1/quotes/${id}/approve`,
                method: 'post',
                values: {},
            },
            {
                onError: (error) => {
                    // An error happened!
                    console.error(error);
                    setLoading(false);
                },
                onSuccess: (data) => {
                    const result = data?.data;
                    if (!result.data?.paymentPlans?.length) {
                        if (isAuthenticated) {
                            push(`/wl/invoices/show/${result.data?.invoiceId}`);
                        } else {
                            push(
                                `/invoices/public/${result.data?.invoiceId}?quoteId=${quote?.id}&token=${token}`,
                            );
                        }
                    }
                    setLoading(false);
                },
            },
        );
        //reset quote action
        setAction(null);
    };

    const handleAfterVerifyOtp = () => {
        switch (action) {
            case 'approve':
                approve();
                break;

            case 'reject':
                rejectQuote();
                break;
            default:
                break;
        }
    };

    const pay = () => {
        if (quote?.invoiceId) {
            if (isAuthenticated) {
                push(`/wl/invoices/show/${quote?.invoiceId}`);
            } else {
                push(`/invoices/public/${quote?.invoiceId}?quoteId=${quote?.id}&token=${token}`);
            }
        }
    };

    const payInvoiceWithPlan = (invoiceId: string) => {
        if (isAuthenticated) {
            push(`/wl/invoices/show/${invoiceId}?quoteId=${quote?.id}`);
        } else {
            push(`/invoices/public/${invoiceId}?quoteId=${quote?.id}&token=${token}`);
        }
    };

    const handleDownload = useReactToPrint({
        content: () => componentToPrint.current,
        copyStyles: true,
        pageStyle: `
        @media all {
            .no-print {
                display: none!important;
            }

            .no-space {
                letter-spacing: 0.01px; 
            }
        }
        `,
        print: async (printIframe: any) => {
            const document = printIframe.contentDocument;
            if (document) {
                const html = document.getElementsByTagName('html')[0];
                html.style.width = '900px';
                const doc = new jsPDF('p', 'px');
                doc.addFileToVFS('inter.ttf', FONT_INTER);
                doc.addFont('inter.ttf', 'inter', 'normal');
                doc.setFont('inter', 'normal');
                doc.html(html, {
                    html2canvas: {
                        scale: 0.5,
                    },
                    async callback(doc) {
                        await doc.save(`Quote #${quote?.incrementId}`);
                    },
                });
            }
        },
    });

    return (
        <>
            {!isLoading && !error && (
                <section
                    className={`block-container quote-detail`}
                    ref={(el) => (componentToPrint.current = el)}
                >
                    <div className="block-heading">
                        <div>
                            <div>
                                <span>
                                    {t('quotes.heading.details')}: {quote?.incrementId}
                                </span>
                            </div>
                            <div className="date-container">
                                <span className="date-title">
                                    {t('quotes.fields.expected_payment_date.label')} -{' '}
                                    {formatDate(quote?.date, 'MMM DD, YYYY')}
                                </span>
                                <span className="date-title">
                                    {t('quotes.fields.dueDate.label')} -{' '}
                                    {formatDate(quote?.dueDate, 'MMM DD, YYYY')}
                                </span>
                            </div>
                        </div>
                        <div className="ml-auto">
                            <Button
                                type="link"
                                icon={<img src="/images/icons/download.svg" />}
                                className="no-print mr-3"
                                onClick={handleDownload}
                            />
                            <ReactToPrint
                                copyStyles={true}
                                pageStyle={`
                                @page { margin: 0; }
                                @media all {
                                    .no-print {
                                        display: none!important;
                                    }
                                }
                            `}
                                trigger={() => (
                                    <Button
                                        type="link"
                                        icon={<img src="/images/icons/print.svg" />}
                                        className="no-print"
                                    />
                                )}
                                content={() => componentToPrint.current}
                            />
                        </div>
                    </div>
                    {quote?.description && (
                        <div className="details-section">
                            <div className="section-header">
                                {t('quotes.fields.description.label')}
                            </div>
                            <Row gutter={20}>
                                <Col md={24}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: quote?.description as string,
                                        }}
                                    ></div>
                                </Col>
                            </Row>
                        </div>
                    )}
                    <div className="details-section">
                        <Row gutter={20}>
                            <Col md={12} className="user-info-col">
                                <div className="user-info">
                                    <div className="info-header">
                                        {t('quotes.heading.createdBy')}
                                    </div>
                                    <div className="name">
                                        {(quote?.createdByUser?.firstName ?? '') +
                                            ' ' +
                                            (quote?.createdByUser?.lastName ?? '')}
                                    </div>
                                    <div className="no-space">
                                        {quote?.createdByUser?.email ?? ''}
                                    </div>
                                    <div className="no-space">
                                        {quote?.createdByUser?.phone
                                            ? maskPhone(quote?.createdByUser?.phone)
                                            : ''}
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} className="user-info-col">
                                <div className="user-info text-right">
                                    <div className="info-header">{t('quotes.heading.for')}</div>
                                    <div className="name">{quote?.contactName}</div>
                                    <div className="no-space">{quote?.contactEmail}</div>
                                    <div className="no-space">
                                        {quote?.contactPhone ? maskPhone(quote?.contactPhone) : ''}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="details-section quote-items-table">
                        <div className="section-header">{t('quotes.heading.itemDetails')}</div>
                        <Row className="item-list">
                            <Col span={24}>
                                <Row className="table-header">
                                    <Col span={6}>{t('quotes.fields.item.label')}</Col>
                                    <Col span={4}>{t('quotes.fields.unitPrice.label')}</Col>
                                    <Col span={4}>{t('quotes.fields.qty.label')}</Col>
                                    <Col span={6}>{t('quotes.fields.frequency.label')}</Col>
                                    <Col span={4}>{t('quotes.fields.total.label')}</Col>
                                </Row>
                                {quote &&
                                    quote?.items?.length > 0 &&
                                    quote?.items?.map((item) => {
                                        return (
                                            <Row key={item.id} className="list-product-item">
                                                <Col span={6}>{item.productName}</Col>
                                                <Col span={4}>
                                                    <span className="unit-product">
                                                        {item?.charge
                                                            ? formatPrice(item.unitPrice)
                                                            : formatPrice(item.total / item.qty)}
                                                        {userIdentity?.userType !==
                                                            UserTypeEnum.CLIENT &&
                                                        item.type === UserTypeEnum.WHITELABEL ? (
                                                            <span>
                                                                <b>
                                                                    {t(
                                                                        'quotes.fields.service_fee.label',
                                                                    )}
                                                                    :{' '}
                                                                </b>
                                                                {item.charge}%
                                                            </span>
                                                        ) : null}
                                                    </span>
                                                </Col>
                                                <Col span={4}>{item.qty}</Col>
                                                <Col span={6}>
                                                    {t('quotes.frequency.' + item.frequency)}
                                                </Col>
                                                <Col span={4}>{formatPrice(item.total)}</Col>
                                            </Row>
                                        );
                                    })}
                            </Col>
                        </Row>
                        <div className="sum-total flex">
                            <span>{t('quotes.heading.totalPrice')}</span>
                            <span className="ml-auto">{formatPrice(getTotalPrice())}</span>
                        </div>

                        {quoteData?.data?.paymentPlans?.length ? (
                            <>
                                <div className="section-header payment-plans-title mt-4 mb-0">
                                    {t('quotes.heading.plans')}
                                </div>
                                {quoteData?.data?.paymentPlans.map((plan, idx) => {
                                    return (
                                        <div>
                                            <div
                                                key={plan?.id}
                                                className="mt-8 payment-plan-detail py-6 px-4"
                                            >
                                                <div className="cursor-pointer flex justify-between mb-6">
                                                    <span className="plan-index">{`#${
                                                        idx + 1 < 10 ? `0${idx + 1}` : idx + 1
                                                    }`}</span>
                                                </div>
                                                <div className="date-container mb-4 flex justify-between">
                                                    <span className="date-title">
                                                        {t('quotes.heading.payment_date')} -{' '}
                                                        {formatDate(
                                                            plan?.paymentDate,
                                                            'MMM DD, YYYY',
                                                        )}
                                                    </span>
                                                    <span className="date-title">
                                                        {t('quotes.heading.payment_due_date')} -{' '}
                                                        {formatDate(
                                                            plan?.paymentDueDate,
                                                            'MMM DD, YYYY',
                                                        )}
                                                    </span>
                                                </div>
                                                <Row className="item-list">
                                                    <Col span={24}>
                                                        <Row className="table-header">
                                                            <Col span={8}>
                                                                {t('quotes.fields.item.label')}
                                                            </Col>
                                                            <Col span={6}>
                                                                {t('quotes.fields.frequency.label')}
                                                            </Col>
                                                            <Col span={6}>
                                                                {t(
                                                                    'quotes.fields.percentage.label',
                                                                )}
                                                            </Col>
                                                            <Col span={4}>
                                                                {t('quotes.fields.total.label')}
                                                            </Col>
                                                        </Row>
                                                        {plan &&
                                                            plan?.paymentPlanItems?.length > 0 &&
                                                            plan?.paymentPlanItems?.map(
                                                                (planItem, itemIdx) => {
                                                                    const {
                                                                        frequency,
                                                                        quoteItemName,
                                                                    } =
                                                                        getPlanItemInformation(
                                                                            planItem,
                                                                        );
                                                                    return (
                                                                        <Row
                                                                            key={planItem.id}
                                                                            className={`list-product-item ${
                                                                                itemIdx + 1 ===
                                                                                (
                                                                                    plan?.paymentPlanItems ||
                                                                                    []
                                                                                ).length
                                                                                    ? 'last-child'
                                                                                    : ''
                                                                            }`}
                                                                        >
                                                                            <Col span={8}>
                                                                                {quoteItemName}
                                                                            </Col>
                                                                            <Col span={6}>
                                                                                {t(
                                                                                    'quotes.frequency.' +
                                                                                        frequency,
                                                                                )}
                                                                            </Col>
                                                                            <Col span={6}>
                                                                                {
                                                                                    planItem.percentage
                                                                                }
                                                                            </Col>
                                                                            <Col span={4}>
                                                                                {formatPrice(
                                                                                    planItem.total,
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                    );
                                                                },
                                                            )}
                                                    </Col>
                                                </Row>
                                                <div className="sum-total flex mt-2">
                                                    <span>
                                                        {t('quotes.heading.totalPlanPrice')}
                                                    </span>
                                                    <span className="ml-auto">
                                                        {formatPrice(getPlanTotalPrice(plan))}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="mt-6 flex justify-end">
                                                {(quote?.status === QuoteStatus.Approved ||
                                                    quote?.status === QuoteStatus.Paid) &&
                                                    quote?.paymentPlans?.length &&
                                                    (!isAuthenticated ||
                                                        useIdentity?.id ===
                                                            quoteData?.data?.contactUserId) && (
                                                        <Button
                                                            onClick={() =>
                                                                payInvoiceWithPlan(plan.invoiceId)
                                                            }
                                                            type="primary"
                                                            className="ml-4"
                                                        >
                                                            {t('quotes.actions.invoice')}
                                                        </Button>
                                                    )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        ) : null}
                    </div>

                    {/* Mobile view items */}
                    <div className="quote-items-list">
                        {quote &&
                            quote?.items?.length > 0 &&
                            quote?.items?.map((item) => {
                                return (
                                    <div className="p-6" key={item.id}>
                                        <Row className="list-product-item">
                                            <Col span={12} className="title">
                                                <span>{t('quotes.fields.item.label')}</span>
                                            </Col>
                                            <Col span={12} className="value">
                                                <span>{item.productName}</span>
                                            </Col>
                                        </Row>
                                        <Row className="list-product-item">
                                            <Col span={12} className="title">
                                                <span>{t('quotes.fields.unitPrice.label')}</span>
                                            </Col>
                                            <Col span={12} className="value">
                                                <span>{formatPrice(item.total / item.qty)}</span>
                                            </Col>
                                        </Row>
                                        <Row className="list-product-item">
                                            <Col span={12} className="title">
                                                <span>{t('quotes.fields.qty.label')}</span>
                                            </Col>
                                            <Col span={12} className="value">
                                                <span>{item.qty}</span>
                                            </Col>
                                        </Row>
                                        <Row className="list-product-item">
                                            <Col span={12} className="title">
                                                <span>{t('quotes.fields.frequency.label')}</span>
                                            </Col>
                                            <Col span={12} className="value">
                                                <span>
                                                    {t('quotes.frequency.' + item.frequency)}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row className="list-product-item">
                                            <Col span={12} className="title">
                                                <span>{t('quotes.fields.total.label')}</span>
                                            </Col>
                                            <Col span={12} className="value">
                                                <span>{formatPrice(item.total)}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}

                        <div className="sum-total flex p-6">
                            <span className="sum-total-title">
                                {t('quotes.heading.totalPrice')}
                            </span>
                            <span className="ml-auto sum-total-value">
                                {formatPrice(getTotalPrice())}
                            </span>
                        </div>

                        {quoteData?.data?.paymentPlans?.length ? (
                            <>
                                <div className="plan-list section-header mt-2 px-6">
                                    {t('quotes.heading.plans')}
                                </div>
                                {quoteData?.data?.paymentPlans.map((plan) => {
                                    return (
                                        <div className="px-6">
                                            <div key={plan.id} className="mt-1">
                                                <div className="date-container mb-2 flex flex-col">
                                                    <span className="date-title">
                                                        {t('quotes.heading.payment_date')} -{' '}
                                                        {formatDate(
                                                            plan?.paymentDate,
                                                            'MMM DD, YYYY',
                                                        )}
                                                    </span>
                                                    <span className="date-title">
                                                        {t('quotes.heading.payment_due_date')} -{' '}
                                                        {formatDate(
                                                            plan?.paymentDueDate,
                                                            'MMM DD, YYYY',
                                                        )}
                                                    </span>
                                                </div>
                                                {plan?.paymentPlanItems?.length > 0
                                                    ? plan?.paymentPlanItems.map((planItem) => {
                                                          const { frequency, quoteItemName } =
                                                              getPlanItemInformation(planItem);
                                                          return (
                                                              <>
                                                                  <Row className="list-product-item">
                                                                      <Col
                                                                          span={12}
                                                                          className="title"
                                                                      >
                                                                          <span>
                                                                              {t(
                                                                                  'quotes.fields.item.label',
                                                                              )}
                                                                          </span>
                                                                      </Col>
                                                                      <Col
                                                                          span={12}
                                                                          className="value"
                                                                      >
                                                                          <span>
                                                                              {quoteItemName}
                                                                          </span>
                                                                      </Col>
                                                                  </Row>
                                                                  <Row className="list-product-item">
                                                                      <Col
                                                                          span={12}
                                                                          className="title"
                                                                      >
                                                                          <span>
                                                                              {t(
                                                                                  'quotes.fields.frequency.label',
                                                                              )}
                                                                          </span>
                                                                      </Col>
                                                                      <Col
                                                                          span={12}
                                                                          className="value"
                                                                      >
                                                                          <span>
                                                                              {frequency
                                                                                  ? t(
                                                                                        'quotes.frequency.' +
                                                                                            frequency,
                                                                                    )
                                                                                  : ''}
                                                                          </span>
                                                                      </Col>
                                                                  </Row>
                                                                  <Row className="list-product-item">
                                                                      <Col
                                                                          span={12}
                                                                          className="title"
                                                                      >
                                                                          <span>
                                                                              {t(
                                                                                  'quotes.fields.percentage.label',
                                                                              )}
                                                                          </span>
                                                                      </Col>
                                                                      <Col
                                                                          span={12}
                                                                          className="value"
                                                                      >
                                                                          <span>
                                                                              {planItem.percentage}
                                                                          </span>
                                                                      </Col>
                                                                  </Row>
                                                                  <Row className="list-product-item mb-4">
                                                                      <Col
                                                                          span={12}
                                                                          className="title"
                                                                      >
                                                                          <span>
                                                                              {t(
                                                                                  'quotes.fields.total.label',
                                                                              )}
                                                                          </span>
                                                                      </Col>
                                                                      <Col
                                                                          span={12}
                                                                          className="value"
                                                                      >
                                                                          <span>
                                                                              {planItem.total}
                                                                          </span>
                                                                      </Col>
                                                                  </Row>
                                                              </>
                                                          );
                                                      })
                                                    : null}
                                                <div className="sum-total plan-total flex mt-1 mb-6">
                                                    <span className="sum-total-title">
                                                        {t('quotes.heading.totalPlanPrice')}
                                                    </span>
                                                    <span className="ml-auto sum-total-value">
                                                        {formatPrice(getPlanTotalPrice(plan))}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="mt-6 flex justify-end">
                                                {(quote?.status === QuoteStatus.Approved ||
                                                    quote?.status === QuoteStatus.Paid) &&
                                                    quote?.paymentPlans?.length &&
                                                    (!isAuthenticated ||
                                                        useIdentity?.id ===
                                                            quoteData?.data?.contactUserId) && (
                                                        <Button
                                                            onClick={() =>
                                                                payInvoiceWithPlan(plan.invoiceId)
                                                            }
                                                            type="primary"
                                                            className="ml-4"
                                                        >
                                                            {t('quotes.actions.invoice')}
                                                        </Button>
                                                    )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        ) : null}
                    </div>

                    {quote?.termsAndConditions && (
                        <div className="details-section">
                            <div className="section-header">{t('quotes.fields.tc.label')}</div>
                            <Row gutter={20}>
                                <Col md={24}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: quote?.termsAndConditions as string,
                                        }}
                                    ></div>
                                </Col>
                            </Row>
                        </div>
                    )}

                    <div className="details-section no-print detail-actions">
                        <div className="flex flex-row-reverse">
                            {quote?.status === QuoteStatus.Pending &&
                                (!isAuthenticated ||
                                    useIdentity?.id === quoteData?.data?.contactUserId) && (
                                    <>
                                        <Button
                                            type="primary"
                                            className="ml-4"
                                            disabled={rejectLoading || isSendingOtp}
                                            loading={loading || isSendingOtp}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleQuoteAction('approve');
                                            }}
                                        >
                                            {t('quotes.actions.approve')}
                                        </Button>
                                        <Popconfirm
                                            onConfirm={(e) => {
                                                e?.preventDefault();
                                                handleQuoteAction('reject');
                                            }}
                                            title="Are you sure to reject?"
                                        >
                                            <Button
                                                disabled={loading || isSendingOtp}
                                                loading={rejectLoading || isSendingOtp}
                                                className="ml-4"
                                                type="dashed"
                                            >
                                                {t('quotes.actions.reject')}
                                            </Button>
                                        </Popconfirm>
                                    </>
                                )}
                            {(quote?.status === QuoteStatus.Approved ||
                                quote?.status === QuoteStatus.Paid) &&
                                !quote?.paymentPlans?.length &&
                                (!isAuthenticated ||
                                    useIdentity?.id === quoteData?.data?.contactUserId) && (
                                    <Button onClick={pay} type="primary" className="ml-4">
                                        {t('quotes.actions.invoice')}
                                    </Button>
                                )}
                            {isAuthenticated && (
                                <Button onClick={goBack}>{t('quotes.actions.cancel')}</Button>
                            )}
                        </div>
                    </div>
                </section>
            )}
            {(isLoading || !!error) && <Spin spinning={true}></Spin>}
            {isOpenOtp && quote?.id ? (
                <OtpConfirm
                    title={
                        action === 'approve'
                            ? t('otp.approve_quote', { ns: 'common' })
                            : t('otp.reject_quote', { ns: 'common' })
                    }
                    visible={isOpenOtp}
                    isSendingOtp={isSendingOtp}
                    confirmData={{
                        email: quote?.contactEmail ?? '',
                        resourceName: OTP_RESOURCE_NAME.QUOTE,
                        id: quote?.id,
                    }}
                    setVisible={setIsOpenOtp}
                    onClose={(isVerified: boolean) => {
                        if (!isVerified) {
                            return;
                        }
                        handleAfterVerifyOtp();
                    }}
                    onResend={() => handleSendOtp()}
                />
            ) : null}
        </>
    );
};
