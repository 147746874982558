import { RangePickerProps } from 'antd/lib/date-picker';
import dayjs from 'dayjs';

export const validateCode = (code: string): boolean => {
    return /^[a-zA-Z0-9d-]+$/.test(code);
};

export const validateEmail = (email: string): boolean => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email?.toLowerCase(),
    );
};

export const validatePhoneNumber = (phone: string): boolean => {
    return /\(\d{3}\)-\d{3}-\d{4}/g.test(phone?.toLowerCase());
};

export const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
};

export const DebounceAsyncValidator = (debounce: number, asyncFunc: () => Promise<boolean>) => {
    let _clearTimeout: (() => void) | null;
    return () =>
        new Promise((resolve, reject) => {
            if (_clearTimeout) {
                _clearTimeout();
            }
            const timerId = setTimeout(async () => {
                const result = await asyncFunc();
                resolve(result);
            }, debounce);

            _clearTimeout = () => {
                clearTimeout(timerId);
                resolve(true);
            };
        });
};

export const validateZipCode = (zipCode: string): boolean => {
    return /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/g.test(zipCode);
};

export const validateSlug = (slug: string): boolean => {
    //https://ihateregex.io/expr/url-slug/
    return /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(slug);
};
