import { MenuEnum } from 'components/layout/sidebar';
import { ResourceEnum } from 'enums/module-resources.enum';

export const BASE_ROUTE = '';
export const PAGE_SIZE = 10;
export const SA_ROLE = '36a34a0d-c41c-4663-b1ec-674f38c52193';
export const DATETIME_LOCALE = 'us';
export const DOCUMENT_TYPE = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const DEFAULT_PRIMARY = '#6b6cff';
export const DEFAULT_SECONDARY = '#f4f4ff';
export const COLORS_CHART = ['#FFCF5C', '#0084F4', '#FFA26B', '#00C48C'];

export const TENANT_BUSINESS_SETTING_RESOURCE = 'v1/settings/brand-setting';

export const WHITE_LABEL_SETTING_RESOURCE = 'v1/wl-settings';
export const FAV_URL_KEY = 'fav_url';

// TODO: define new menu item with order follow by it's order on sidebar
export const MODULE_ITEMS_ORDER: {
    [key in MenuEnum]?: { [key in ResourceEnum]?: ResourceEnum };
} = {
    dashboard: {},
    wl: {
        wl_revenue: ResourceEnum.WLRevenue,
        wl_service: ResourceEnum.WLService,
        wl_partner: ResourceEnum.WLPartner,
        wl_setting: ResourceEnum.WLSetting,
        wl_payouts: ResourceEnum.WLPayouts,
        wl_contact: ResourceEnum.WLContact,
        wl_quote: ResourceEnum.WLQuote,
        wl_invoice: ResourceEnum.WLInvoice,
        wl_order: ResourceEnum.WLOrder,
        wl_recurringInvoice: ResourceEnum.WLRecurringInvoice,
        wl_helpdesk: ResourceEnum.WLHelpdesk,
    },
    pos: {
        pos_report: ResourceEnum.POSReport,
        pos_shop: ResourceEnum.POSShop,
        pos_table: ResourceEnum.POSTable,
        pos_tableGroup: ResourceEnum.POSTableGroup,
        pos_employee: ResourceEnum.POSEmployee,
        pos_extraFee: ResourceEnum.POSExtraFee,
        pos_payouts: ResourceEnum.POSPayouts,
        pos_setting: ResourceEnum.POSSetting,
        pos_generalSetting: ResourceEnum.POSGeneralSetting,
        pos_paymentSetting: ResourceEnum.POSPaymentSetting,
        pos_equipmentSetting: ResourceEnum.POSEquipmentSetting,
        pos_printSetting: ResourceEnum.POSPrintSetting,
        pos_paymentTerminal: ResourceEnum.POSPaymentTerminalSetting,
        pos_item: ResourceEnum.POSItem,
        pos_category: ResourceEnum.POSCategory,
        pos_option: ResourceEnum.POSOption,
    },
    crm: {},
};

export const DATE_FORMAT = 'MM-DD-YYYY';
export const DATE_FORMAT_PERIOD = 'MM-DD-YYYY hh:mm a';
export const DATE_FORMAT_MINUTE = 'MM-DD-YYYY HH:mm';
export const DATE_FORMAT_TIME = 'MM-DD-YYYY HH:mm:ss';

export const OTP_RESOURCE_NAME = {
    QUOTE: 'quote',
    INVOICE: 'invoice',
    PAYOUT: 'payout',
};

export const SERVICE_OTHER_OPTION_ID = '965ac398-6fdc-4112-beb7-8e5cdebba34c';

export const PIN_LENGTH = 6;

export const TABLET_SIZE = 990;

export const APPLICATIONS = {
    POS: {
        id: '42ec3ebe-54ed-4c11-b911-fab78c1f81c2',
        code: 'POS',
    },
};
